import React from "react"
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import Clock from '../components/clock'
import Layout from "../components/layout"
import InsetContent from '../components/inset-content'
import NoBreak from '../components/no-break'
import SEO from "../components/seo"
import TooltipList from '../components/tooltip-list'
import {
  LINE_GAP,
  BREAKPOINT_M,
  M_SPACING_Y
} from '../lib/cssConstants'

import 'focus-visible'

const indentParagraphs = props =>
  props.indent && css`
    p + p {
      text-indent: var(--text-indent);
    }
  `

const Wrapper = styled('div')`
  position: relative;
  display: flex;
  min-height: 100vh;
  padding: 10px 0;

  @media (max-width: ${BREAKPOINT_M}) {
    flex-direction: column;
    margin-left: calc(30px + var(--dot-diameter));
    margin-right: 10px;
    border-left: 1px solid;
    border-right: 1px solid;
  }
`

const Column = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 25%;
  padding: 0 10px;
  border-left: 1px solid;


  @media (max-width: ${BREAKPOINT_M}) {
    width: 100%;
    border-left: none;

    & + ${() => Column} {
      margin-top: ${M_SPACING_Y};
    }
  }
`

const BlankColumn = styled(Column)`
  @media (max-width: ${BREAKPOINT_M}) {
    display: none;
  }
`

const TextContainer = styled('div')`
  ${indentParagraphs}

  font-style: inherit;
  max-width: var(--text-width);

  & + ${() => TextContainer} {
    margin-top: 60px;

    @media (max-width: ${BREAKPOINT_M}) {
      margin-top: ${M_SPACING_Y};
    }
  }
`

const ColumnTitle = styled('h2')`
  margin-bottom: 1.2em;
  font-size: inherit;
  text-transform: uppercase;
  letter-spacing: var(--l-spacing-caps);
`

const InlineTitle = styled('h2')`
  margin-top: ${LINE_GAP};
  
  &:first-of-type {
    margin-top: 0;
  }
`

const Binder = styled('div')`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 400px;
  margin: auto 0;
  padding: 0 10px;
  pointer-events: none;

  @media (max-width: ${BREAKPOINT_M}) {
    left: 0;
  }
`

const SecondBinder = styled(Binder)`
  left: auto;
  right: 50%;

  @media (max-width: ${BREAKPOINT_M}) {
    display: none;
  }
`

const Dot = styled('div')`
  width: var(--dot-diameter);
  height: var(--dot-diameter);
  border-radius: 50%;
  background-color: var(--color-text);
`

const Tabular = styled.span`
  font-feature-settings: 'tnum' on;
  font-feature-settings: 'kern';
  word-spacing: 0.075rem;
`

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  width: 50%;

  > * {
    padding: 5px 10px;
    width: 50%;

    @media (min-width: ${BREAKPOINT_M}) {
      width: 50%;
    }
  }

  @media (max-width: ${BREAKPOINT_M}) {
    position: static;
    justify-content: space-between;
    flex-direction: row-reverse;
    width: 100%;
  }
`

const IndexPage = () => (
  <Layout>
    <SEO title="1/1 Studio" />
    <Wrapper>
      <Binder>
        <Dot />
        <Dot />
      </Binder>
      <SecondBinder>
        <Dot />
        <Dot />
      </SecondBinder>
      <Column css={{ borderLeft: 'none' }}>
        <h1
          css={{
            marginBottom: '60px',
            textTransform: 'uppercase',
            letterSpacing: 'var(--l-spacing-caps)'
          }}
        >
          <span className="tabular">1 &#x2044; 1</span> Studio
        </h1>
        <TextContainer as="address">
          <InlineTitle>
            Enquiries
          </InlineTitle>
          <InsetContent bullet="E.">
            <a href="mailto:office@1of1studio.com" target="_blank" rel="noreferrer">
              office@<span className="tabular">1</span>of<span className="tabular">1</span>studio.com
            </a>
          </InsetContent>
          <InlineTitle>
            Studio
          </InlineTitle>
          <InsetContent bullet="A.">
            <span className="tabular">1</span> Cascade St<br></br>
            Freemans Bay,<br></br>
            Auckland, <span className="tabular">1011</span><br></br>
            New Zealand
          </InsetContent>
        </TextContainer>
        <TextContainer>
          <InlineTitle>
            Creative Director
          </InlineTitle>
          <InsetContent>
            Natasha Mead
          </InsetContent>
          <InsetContent bullet="E.">
            <a href="mailto:natasha@1of1studio.com" target="_blank" rel="noreferrer">
              natasha@<span className="tabular">1</span>of<span className="tabular">1</span>studio.com
            </a>
          </InsetContent>
          <InsetContent bullet="T.">
            <a href="tel:+64274217669" className="tabular">
              +64 27 421 7669
            </a>
          </InsetContent>
          <a href="https://www.instagram.com/natashamead" target="_blank" rel="noreferrer">
            Instagram
          </a>
          <InlineTitle>
            Technology Director
          </InlineTitle>
          <InsetContent>
            Joe Swann
          </InsetContent>
          <InsetContent bullet="E.">
            <a href="mailto:joe@1of1studio.com" target="_blank" rel="noreferrer">
              joe@<span className="tabular">1</span>of<span className="tabular">1</span>studio.com
            </a>
          </InsetContent>
          <InsetContent bullet="T.">
            <a href="tel:+64210484703" className="tabular">
              +64 21 0484 703
            </a>
          </InsetContent>
        </TextContainer>
      </Column>
      <Column css={{ paddingRight: '40px' }}>
        <TextContainer indent={true}>
          <InsetContent bullet="1.">
            <ColumnTitle>Approach</ColumnTitle>
          </InsetContent>
          <p><span className="tabular test">1</span> of <span className="tabular">1</span> is a creative studio based in Auckland, <NoBreak>New Zealand.</NoBreak></p> 
          <p>We partner with thoughtful and progressive fashion, art, and lifestyle brands in articulating unique visions across disciplines. Regarding each project as an independent, ongoing case study, we work alongside brands in every part of the process — from initial ideation to the ongoing evolution of concept and strategy.</p>
          <p>We draw inspiration from analog nuance and intentional subtraction, with an emphasis on tactility. We’re drawn to concepts that feel distinct yet familiar, purposeful and smart.</p>
          <p>We love connecting with brands of any size who share a similar philosophy.</p>
        </TextContainer>
      </Column>
      <BlankColumn />
      <Column css={{ paddingBottom: '80px' }}>  
        <TextContainer>
          <InsetContent bullet="2.">
            <ColumnTitle>Offering</ColumnTitle>
          </InsetContent>
          <ul>
            <li>Brand Identity </li>
            <li>Art Direction </li>
            <li>Print Collateral</li>
            <li>Packaging Design</li>
            <li>Digital Assets</li>
            <li>Publications</li>
          </ul>
          <ul>
            <li>Website Design</li>
            <li>Website Development</li>
            <li>E-Commerce</li>
            <li>Integrations</li>
            <li>Custom Applications</li>
            <li>Technical Consultation</li>
          </ul>
        </TextContainer>
        <TextContainer>
          <InsetContent bullet="3.">
            <ColumnTitle>Case Studies</ColumnTitle>
          </InsetContent>
        </TextContainer>
        <TooltipList
          listItems={[]}
        />
      </Column>
      <Footer>
        {/* <div>
          © <span className="tabular">1 of 1</span> Ltd.
        </div> */}
        <Clock />
        <p css={{ maxWidth: 'var(--text-width)' }}>
          <NoBreak><Tabular>1</Tabular> of <Tabular>1</Tabular> is </NoBreak><NoBreak>Natasha Mead</NoBreak>, <NoBreak>Joe Swann</NoBreak>, <NoBreak>Devon Taylor</NoBreak>, <NoBreak>Joshua Briones-Yap</NoBreak>, <NoBreak>Luke Hoban</NoBreak>, <NoBreak>Nicole Smith</NoBreak> and <NoBreak>Natalie Thompson</NoBreak>
        </p>
      </Footer>
    </Wrapper>
  </Layout>
)

export default IndexPage
