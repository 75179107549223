/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'
import Unica from '../fonts/unica-77-regular.woff2'
import { useStaticQuery, graphql } from "gatsby"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Global
        styles={css`
          :root {
            --text-indent: 35px;
            --text-width: 278px;
            --l-spacing-caps: em;

            --color-text: #000000;
            --color-muted: #857F8A;
            
            --shadow-color: rgb(226, 224, 216);

            --dot-diameter: 12px;
          }

          @font-face {
            font-family: "Unica77LLWebRegular";
            src: url(${Unica}) format("woff2");
          }

          * {
            box-sizing: border-box;
          }

          html {
            background-color: #F7F6F0;
            font-family: "Unica77LLWebRegular", sans-serif;
            font-size: 12px;
            line-height: 1.3;
            letter-spacing: 0.01em;
            
            @media (max-width: 900px) {
              font-size: 14px;
            }
          }

          body {
            margin: 0;
          }

          h1,
          h2,
          h3,
          h4,
          p {
            font-size: inherit;
            font-weight: normal;
            margin: 0;
          }

          ul {
            display: flex;
            flex-direction: column;
            margin: 1em 0;
            padding: 0;
          }

          li {
            list-style: none;
          }

          a {
            color: currentColor;
            text-decoration-color: transparent;
          }

          button {
            padding: 0;
            appearance: none;
            color: currentColor;
            background: none;
            border: none;
            cursor: pointer;
            font-size: inherit;
            text-align: initial;
            text-rendering: optimizeLegibility;
            font-weight: 400;
          }

          ::selection {
            background-color: var(--shadow-color);
          }

          .tabular {
            font-feature-settings: 'tnum' on;
            font-feature-settings: 'kern';
            word-spacing: 0.075rem;
          }

          .orphan {
            white-space: nowrap;
          }
          
          a,
          .hoverBackground {
            &.focus-visible:focus,
            &:hover {
              background: var(--shadow-color);
              box-shadow: 0 0 0px 3px var(--shadow-color);
            }
          }

          :focus:not(.focus-visbile) {
            outline: none;
          }
        `}
      />
      <div>
        <main>{children}</main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
