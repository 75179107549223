import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Transition, CSSTransition } from 'react-transition-group'
import { css } from '@emotion/core'
import InsetContent from './inset-content'
import { BREAKPOINT_M } from '../lib/cssConstants'

const List = styled('ul')`
  max-width: calc(var(--text-width) / 2);
  margin-top: 0;
  transition: color 0.3s ease;

  /* &:hover {
    ${() => TooltipAnchor} {
      color: var(--color-muted);
    }
  } */
`

const InteractiveListItem = styled('li')`
  position: relative;
  display: flex;

  /* @media (max-width: ${BREAKPOINT_M}) {
    display: none;
  } */
`

const ListItem = styled.li`
  @media (min-width: ${BREAKPOINT_M}) {
    display: none;
  }
`

const TooltipAnchor = styled.a`
  position: relative;
  padding-right: 25px;
  font-family: inherit;
  cursor: pointer;

  &:after {
    content: '\\2197';
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-2px, 2px);
    opacity: 0;
    transition: all 0.15s ease;

    @media (max-width: ${BREAKPOINT_M}) {
      display: none;
    }
  }

  &:focus,
  &:hover {
    color: var(--color-text) !important;

    &:after {
      opacity: 1;
      transform: translate(0);
    }
  }
`

const Tooltip = styled('div')`
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;

  @media (max-width: ${BREAKPOINT_M}) {
    display: none;
  }
`

const tooltips = [
  {
    heading: 'Binu Binu',
    url: 'https://binu-binu.com/',
    services: [
      'Brand Identity',
      'Web Design',
      'Web Development',
      'E-Commerce',
      'Packaging',
      'Print Collateral',
      'Digital Assets'
    ]
  },
  {
    heading: 'Baina',
    url: 'https://shopbaina.com/',
    services: [
      'Brand Identity',
      'Web Design',
      'Web Development',
      'E-Commerce',
      'Packaging',
      'Print Collateral',
      'Digital Assets'
    ]
  },
  {
    heading: 'Simone Bodmer-Turner',
    url: 'https://simonebodmerturner.com',
    services: [
      'Web Design',
      'Web Development',
      'E-commerce',
      'Print Collateral'
    ]
  },
  {
    heading: 'J. Hannah',
    url: 'https://jhannahjewelry.com',
    services: [
      'Brand Identity',
      'Web Design',
      'Web Development',
      'E-Commerce',
      'Packaging',
      'Print Collateral',
      'Digital Assets'
    ]
  },
  {
    heading: 'Everday Needs',
    url: 'https://everyday-needs.com',
    services: [
      'Web Design',
      'Web Development',
      'E-Commerce',
      'Packaging',
      'Print Collateral',
      'Digital Assets'
    ]
  },
  {
    heading: 'LoQ',
    url: 'https://www.loq.us',
    services: [
      'Brand Identity',
      'Web Design',
      'Web Development',
      'E-Commerce',
      'Packaging',
      'Print Collateral',
      'Digital Assets'
    ]
  },
  {
    heading: 'LESSE',
    url: 'https://lesseofficial.com',
    services: [
      'Brand Identity',
      'Web Design',
      'Web Development',
      'E-Commerce',
      'Packaging',
      'Print Collateral',
      'Digital Assets'
    ]
  },
  {
    heading: 'Shaina Mote',
    url: 'https://shainamote.com',
    services: [
      'Brand Refresh',
      'Print Collateral',
      'Digital Assets'
    ]
  },
  {
    heading: 'F. Miller',
    url: 'https://fmillerskincare.com',
    services: [
      'Brand Identity',
      'Web Design',
      'Web Development',
      'E-Commerce',
      'Packaging',
      'Print Collateral',
      'Digital Assets'
    ]
  },
  {
    heading: 'Douglas & Bec',
    url: 'https://douglasandbec.com',
    services: [
      'Brand Identity',
      'Web Design',
      'Web Development',
      'E-Commerce',
      'Packaging',
      'Print Collateral',
      'Digital Assets'
    ]
  },
  {
    heading: 'Context Gallery',
    url: 'https://contextgallery.com',
    services: [
      'Brand Identity',
      'Web Design',
      'Packaging',
      'Print Collateral',
      'Digital Assets'
    ]
  },
  {
    heading: 'Daniel Boddam',
    url: 'https://danielboddam.com',
    services: [
      'Brand Identity',
      'Web Design',
      'Web Development',
      'E-Commerce',
      'Packaging',
      'Print Collateral',
      'Digital Assets'
    ]
  },
  {
    heading: 'Venn Studio',
    url: 'https://vennstudiola.com',
    services: [
      'Brand Identity',
      'Web Design',
      'Web Development',
      'Packaging',
      'Print Collateral',
      'Digital Assets'
    ]
  },
  {
    heading: 'ARE Studio',
    url: 'https://are-studio.com',
    services: [
      'Brand Refresh',
      'Web Design',
      'Web Development',
      'E-Commerce',
      'Packaging',
      'Print Collateral',
      'Digital Assets'
    ]
  },
  {
    heading: 'Moon Lists',
    url: 'https://moonlists.com',
    services: [
      'Brand Identity',
      'Publication Design',
      'Web Design',
      'Print Collateral',
      'Digital Assets'
    ]
  },
  {
    heading: 'Anyonegirl',
    url: 'http//anyonegirl.com',
    services: [
      'Brand Identity',
      'Web Design',
      'Publication Design',
      'Printed Collateral',
    ]
  },
  {
    heading: 'Pantograph Punch',
    url: 'https://pantograph-punch.com',
    services: [
      'Brand Identity',
      'Web Design',
      'Web Development',
    ]
  },
  {
    heading: 'Penny Sage',
    url: 'https://pennysage.com',
    services: [
      'Web Design',
      'Web Development',
      'E-Commerce',
      'Packaging',
      'Print Collateral',
      'Digital Assets'
    ]
  },
  {
    heading: '__S____O',
    url: 'https://www.instagram.com/__s____o',
    services: [
      'Brand Identity',
      'Digital Assets'
    ]
  },
  {
    heading: 'Sans [ceuticals]',
    url: 'https://sansceuticals.com',
    services: [
      'Print Collateral',
      'Packaging',
      'Digital Assets'
    ]
  },
]

const TooltipList = ({ listItems }) => {
  const [activeItem, setActiveItem] = useState(null)
  // const [showEl, setEl] = useState(false);

  // const keyHandler = (event, item) => {
  //   event.persist()
  //   if (event.charCode === 32) {
  //     setActiveItem(item)
  //   }
  // }

  const defaultStyle = {
    transition: `opacity 300ms ease-in-out`,
    opacity: 0,
  }

  const transitionStyles = {
    entering: { opacity: 1 },
    entered:  { opacity: 1 },
    exiting:  { opacity: 0 },
    exited:  { opacity: 0 },
  }  

  const Fade = ({ in: inProp }) => (
    <CSSTransition
      in={inProp}
      timeout={300}
      style={{
        opacity: 0,
        entering: { opacity: 1 },
        entered: { opacity: 1 },
        exiting: { opacity: 0 },
        exited: { opacity: 0 },
      }}
    >
      <div>
        test
      </div>
    </CSSTransition>
  )

  return (
  <List css={{
    width: '50%'
  }}>
    {tooltips.map((tooltip, tooltipIndex) => (
      <InteractiveListItem key={tooltip.heading}>
        <TooltipAnchor
          href={tooltip.url}
          target="_blank"
          rel="noopener"
          onMouseEnter={() => setActiveItem(tooltipIndex)}
          onMouseLeave={() => setActiveItem(null)}
          // onKeyPress={(e) => keyHandler(e, tooltipIndex)}
          onFocus={() => setActiveItem(tooltipIndex)}
          onBlur={() => setActiveItem(null)}
          className="hoverBackground"
        >
          { tooltip.heading }
        </TooltipAnchor>
        <Transition
          in={activeItem === tooltipIndex}
          timeout={300}
        >
          {state => (
            <Tooltip style={{ ...transitionStyles[state] }}>
              <InsetContent>
                <List css={{ marginTop: 0 }}>
                {tooltip.services.map(service => (
                  <li key={service}>
                    {service}
                  </li>
                ))}
                </List>
              </InsetContent>
            </Tooltip>
          )}
        </Transition>
      </InteractiveListItem>
    ))}
  </List>
)}

export default TooltipList
