import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { format } from 'date-fns-tz'
import NoBreak from './no-break'
import { BREAKPOINT_M } from '../lib/cssConstants'

const Container = styled.div`
  @media (max-width: ${BREAKPOINT_M}) {
    text-align: right;
  }
`
const getTime = () => format(
  new Date(),
  'hh:mm a',
  { timeZone: 'Pacific/Auckland' }
)

const getDate = () => format(
  new Date(),
  'EEEE do',
  { timeZone: 'Pacific/Auckland' }
)

function Clock () {
  const [timeString, setTimeString] = useState(getTime())
  const [dateString, setDateString] = useState(getDate())

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeString(getTime())
      setDateString(getDate())
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <Container className="tabular">
      <NoBreak css={{ marginRight: 'calc(1ch * 0.35)' }}>NZT {timeString}</NoBreak>
      <NoBreak>{dateString}</NoBreak>
    </Container>
  )
}

export default Clock