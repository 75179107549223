import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled('div')`
  position: relative;
  padding-left: var(--text-indent);
`

const Bullet = styled('span')`
  position: absolute;
  left: 0;
  width: var(--text-indent);
  max-height: 100%;
  /* letter-spacing: -0.15em; */
`

const InsetContent = ({ children, bullet }) => (
  <Wrapper>
    <Bullet className="tabular">{ bullet }</Bullet>
    { children }
  </Wrapper>
)

export default InsetContent